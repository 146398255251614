import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FormLabel, FlexContainer } from 'components'
import { Append } from './styles'

type TProps = {
  required?: boolean
  type?: string
  name: string
  label?: string
  placeholder?: string
  emptyLabel?: string
  description?: string
  isInvalid?: boolean
  errorMessage?: string
  disabled?: boolean
  max?: number
  min?: number
  autoFocus?: boolean
  maxLength?: number
  onBlur?(e: TInputEvent): void
  appendLabel?: string
  helpIcon?: React.ReactNode
  step?: number
  inputRef?: any
}

const TextField = (props: TProps) => {
  const {
    required,
    label,
    type = 'text',
    name,
    errorMessage,
    description,
    disabled = false,
    appendLabel,
    helpIcon,
    inputRef,
    maxLength,
    ...rest
  } = props

  return (
    <Form.Group>
      <FlexContainer>
        <FormLabel required={required}>
          {label}
        </FormLabel>
        {helpIcon}
      </FlexContainer>

      <InputGroup>
        <Form.Control
          id={name}
          type={type}
          disabled={disabled}
          autoComplete='new-off'
          ref={inputRef}
          maxLength={maxLength}
          {...rest}
        />

        {appendLabel && (
          <Append>
            <InputGroup.Text>{appendLabel}</InputGroup.Text>
          </Append>
        )}

        <Form.Control.Feedback type='invalid'>
          {errorMessage}
        </Form.Control.Feedback>
      </InputGroup>
      {description && (
        <Form.Text className='text-muted'>
          {description}
        </Form.Text>
      )}
    </Form.Group>
  )
}

export default TextField
